<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="user"
        :loadingMsg="loadingMsg"
        navigation="users"
        :refreshToggle="refreshToggle"
        title="User"
        :trackIgnore="['roles']">
        <template v-slot="{ item, data }">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.userName"
                            label="Username"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.email"
                            label="Email Address"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.firstName"
                            label="First Name"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.lastName"
                            label="Last Name"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.phoneNumber"
                            label="Phone Number"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-Select
                            navigation="locations"
                            v-model="item.defaultLocationID"
                            itemText="locationName"
                            label="Main Location"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col v-if="data.isNew" cols="12">
                        <BT-Field-String
                            v-model="item.password"
                            label="Password"
                            :rules="passwordRules()"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-Checkbox
                            v-model="item.isGlobalAdmin"
                            label="Global Admin"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col v-if="item.roles != null" cols="12">
                        <div class="d-flex align-center">
                            <v-subheader>Roles & Access</v-subheader>
                            <v-spacer />
                            <div style="max-width: 250px;">
                                <BT-Select
                                    @change="role => addRole(item, role)"
                                    itemText="roleName"
                                    label="Add Role"
                                    navigation="roles"
                                    :onFilter="list => list.filter(x => !item.roles.some(z => z.id == x.id))" />
                            </div>
                        </div>
                        
                        <v-list>
                            <v-list-item v-for="(role, ind) in item.roles" :key="ind" class="mouse-item">
                                <v-card class="ma-1" width="100%">
                                    <div class="d-flex align-center justify-stretch">
                                        <v-col cols="11" sm="5">
                                            <v-card-title>{{ role.roleName }}</v-card-title>
                                            <v-card-text>{{ role.description }}</v-card-text>
                                        </v-col>
                                        <v-col cols="6" class="text-center d-none d-sm-block">
                                            <div v-for="(p, ind) in permissionToList(role.permissions, 5)" :key="ind">
                                                {{ p }}
                                            </div>
                                            <div v-if="role.permissions != null && role.permissions.split(',').length > 5" class="grey--text text-caption">
                                                +{{ role.permissions.split(',').length - 5 }} other permissions
                                            </div>
                                        </v-col>
                                        <v-col cols="1" class="d-flex align-center">
                                            <v-btn @click.stop="() => removeRole(item, role)" icon class="error--text mouse-over-show"><v-icon>mdi-delete</v-icon></v-btn>
                                        </v-col>
                                    </div>
                                </v-card>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
            
            <!-- <v-expansion-panels v-if="!data.isNew" v-model="panelV" multiple>
                <BT-Blade-Expansion-Items
                    addBladeName="role"
                    :canExportCSV="false"
                    :canRefresh="false"
                    :headers="[
                        { text: 'Role Name', value: 'roleName', title: 1 },
                        { text: 'Description', value: 'description', subtitle: 1 }]"
                    hideActions
                    :items="item.roles"
                    showList
                    title="Roles">
                    <template v-slot:actions>
                        <v-btn class="primary" small :to="{ name: 'users-board' }">Manage</v-btn>
                    </template>
                </BT-Blade-Expansion-Items>
                <BT-Blade-Expansion-Items
                    :canExportCSV="false"
                    :canRefresh="false"
                    :canSelect="false"
                    hideActions
                    :items="item.roles"
                    :onPullSuccessAsync="pullPermissions"
                    showList
                    title="Permissions">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-action>
                            <v-row dense>
                                <v-icon title="View" small :class="item.canView || item.canEdit ? 'success--text' : ''" left>mdi-eye</v-icon>
                                <v-icon title="Edit" small :class="item.canEdit ? 'success--text' : ''" right>mdi-pencil</v-icon>
                            </v-row>
                        </v-list-item-action>
                        <v-list-item-content>
                            {{ item.text }}
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels> -->

            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'User-Blade',
    data: function() {
        return {
            loadingMsg: null,
            msg: null,
            panelV: [0,1],
            refreshToggle: false,
        }
    },
    props: {
        bladesData: null
    },
    computed: {
        permissionToList() {
            return (permissions, cnt = null) => {
                const e = permissions.split(',');
                if (cnt != null && e.length > cnt) {
                    return [...e.slice(0, cnt)]
                }
                else {
                    return [...e];
                }
            };
        },
    },
    methods: {
        pullPermissions(roles) {
            if (!this.isLengthyArray(roles)) {
                return [];
            }

            return roles
                .flatMap(x => {
                    return x.permissions.split(',');
                })
                .reduce((prev, curr) => {
                    var split = curr.split('.');
                    var p = {
                        text: this.capitalizeWords(split[0].replaceAll('-', ' ')),
                        value: split[0],
                        canEdit: false,
                        canView: false
                    }

                    var existing = prev.find(y => y.value == p.value);
                    if (existing == null) {
                        existing = p;
                        prev.push(existing);
                    }

                    if (split.length > 1) {
                        //can view or can edit?
                        if (split[1] == 'edit') {
                            existing.canEdit = true;
                        }
                        if (split[1] == 'view') {
                            existing.canView = true;
                        }
                    }
                    
                    return prev;
                }, [])
                .sort(firstBy(x => x.text, { ignoreCase: true }));
        },
        async addRole(user, role) {
            try {
                this.loadingMsg = 'Adding Role';
                await this.$BlitzIt.api.post('roles', { userID: user.id, roleID: role.id }, null, '/post/AddRole');
            }
            finally {
                this.loadingMsg = null;
                this.refreshToggle = !this.refreshToggle;
            }
        },
        async removeRole(user, role) {
            try {
                this.loadingMsg = 'Removing Role';
                await this.$BlitzIt.api.post('roles', { userID: user.id, roleID: role.id }, null, '/post/RemoveRole');
            }
            finally {
                this.loadingMsg = null;
                this.refreshToggle = !this.refreshToggle;
            }
        }
    }
}
</script>